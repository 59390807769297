import { StaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import React from "react";

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query {
        allJson {
          edges {
            node {
              books {
                name
                destination
                logo
              }
            }
          }
        }
      }
    `}
    render={data =>
      <Layout>
        <div className="row">
          { data.allJson.edges[0].node.books.map(book => (
            <div className="col-md-3">
              <a href={book.destination} className="blog-entry element-animate" data-animate-effect="fadeIn">
                <img src={book.logo} alt={book.name} />
                  <div className="blog-content-body">
                    <h2>{book.name}</h2>
                  </div>
              </a>
            </div>
          ))}
        </div>
      </Layout>
    }
    />
)

export default IndexPage
