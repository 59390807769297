import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header role="banner">
    <div className="container logo-wrap">
      <div className="row pt-5">
        <div className="col-12 text-center">
          <h1 className="site-logo">{siteTitle}</h1>
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
